.svgh2d-c8c9c4aa {
    height: 35px;
    left: 14px;
    position: absolute;
    top: 42px;
    width: 343px;
}

.divjss519 {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    position: relative;
    width: 50%;
}


.key-set {
  height: 121px;
  min-width: 342.82px;
  position: relative;
}


.bottomKeys {
  align-items: center;
  display: flex;
  height: 48px;
  left: 0;
  min-width: 326px;
  // padding: 0 19.1px;
  position: absolute;
  top: 71px;
}

.topKeys {
  align-items: flex-start;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  left: 21px;
  min-width: 326px;
  padding: 13px 4.9px;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .svgh2d-c8c9c4aa {
  height: 25px;
  left: 10px;
  top: 20px;
  width: 100%;
  }

  .divjss519 {
  padding: 0px 10px;
  width: 150px;
  }

  .key-set {
  height: 80px;
  min-width: 100%;
  }

  .bottomKeys {
  top: 45px;

  }

  .topKeys {
  height: 30px;
  justify-content: center;
  left: 0;
  padding: 10px 0;
  top: 0;
  width: 100%;
  }
}