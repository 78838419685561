@import "vars.scss";


.h1text-lg {
  align-items: flex-start;
  display: flex;
  padding: 0px 3.17px 0.23px 0px;
  position: relative;
  width: fit-content;
  
}

.h1text-m  {
  @extend .montserrat-normal-dawn-pink-12px ;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  border-right: transparent;
  border-left: transparent;
  padding: 6.5px 4px 6.5px 4px;
  transition: background-color 0.3s ease;
}

.key-label { 
    @extend .valign-text-middle;
    @extend .montserrat-medium-dawn-pink-12px;
    height: 20px;
    line-height: 19.9px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    text-align: center;
    min-width: 10px;
    background-color: transparent

}

.key-label-top { 
   margin-top: -2px;
   margin-left: 15px;
}

.key-label-bottom {
    margin-bottom: 1.92px;
    margin-left: 7px; 
    margin-right: 25px;

}

.chosen-key {
    align-items: flex-start;
    background-color: #121113;
    border: 2px solid;
    border-color: var(--siren);
    border-radius: 8px;
    display: flex;
    // margin-left: 26px;
    padding: 14.04px 19.11px 13 .96px 18.89px ;
    position: relative;
    width: fit-content;
}


@media only screen and (max-width: 767px) {
  .h1text-lg {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .key-label-top {
    margin-left: 0;
  }
  
  .key-label-bottom {
    margin-left: 0;
    margin-right: 0;
  }
  
  .chosen-key {
    margin-left: 0;
    padding: 10px;
  }
}
