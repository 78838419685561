@import "vars.scss";

.toggle-button-container {
    align-items: center;
    display: flex;
    height: 37px;
    position: relative;
    width: 179px;
    justify-content:space-evenly
}

.toggle-button-label {
    @extend  .valign-text-middle;
    @extend .montserrat-normal-dawn-pink-12px;
    height: 20px;
    letter-spacing: 0.4px;
    line-height: 19.9px;
    margin-bottom: 2.78px;
    min-width: 38px;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.toggle-button-label.minor {
    left: 10px;
}

.toggle-button-label.major {
  right: 10px;
//   top: -25px;
}

// .toggle-button-switch {
//   background-color: #edba0a;
//    border-radius: 10px;
//     box-shadow: 0px 1px 3px #0000001f , 0px 1px 1px#00000024, 0px 2px 1px - 1px #00000033;
//     height: 20px;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 20px
// }

.toggle-button-switch.minor {
  left: 2px;
  transform: translateX(0%);
}

.toggle-button-switch.major {
  right: 2px;
  transform: translateX(100%);
}

.span-mui-switch-root{
    align-items: center;
    align-self: flex-end;
    display: flex;
    height: 36px;
    margin-bottom: -1px;
    margin-left: 4px;
    padding: 0 9px;
    width: 58px;
}

.toggle-button-row,
.span-container{
    height: 20px;
    position: relative;
    width: 37px;
}

.toggle-button,
.spanjss522{
    background-color: #ffffff;
    border-radius: 7px;
    height: 12px;
    left: 3px;
    opacity: 0.3;
    position: absolute;
    top: 4px;
    width: 34px;
}

.toggle-button-switch,
.span-mui-switch-thumb{
    background-color: #edba0a;
    border-radius: 10px;
    box-shadow: Ox 1px 3px #0000001f , 0px 1px 1px#00000024, 0px 2px 1px - 1px #00000033;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px
}


.minor-1{
    height: 20px;
    letter-spacing: 0.40px;
    line-height: 19.9px;
    margin-bottom: 3px;
    margin-left: 11px;
    min-width: 37px;
    white-space: nowrap;
}

// Responsive styling for mobile devices
@media (max-width: 767px) {
  .toggle-button-container {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  }
  .toggle-button-label.minor {
      left: 5%;
    //   top: -25px;
  }

  .toggle-button-label.major {
      right: 5%;
    //   top: -25px;
  }

  .span-mui-switch-root {
      margin-left: 0;
      margin-bottom: 0;
      padding: 0;
      width: 46px;
      height: 28px;
  }

  .toggle-button-row,
  .span-container {
      height: 12px;
      width: 24px;
  }

  .toggle-button,
  .spanjss522 {
      width: 20px;
      height: 8px;
      top: 2px;
      left: 2px;
  }

  .toggle-button-switch,
  .span-mui-switch-thumb {
      width: 12px;
      height: 12px;
  }

}