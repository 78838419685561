/* Variables */
:root {
  --black: rgba(0, 0, 0, 1);
  --siren: rgba(129, 3, 47, 1) ;
  --dawn-pink: rgba(243, 233, 220,
  0.902);
  --dawn-pink-2: rgba(243, 233, 220,
  1);
  --font-size-s: 11.9px;
  --font-size-m: 12px;
  --font-size-1: 14px;
  --font-size-xl: 16px;
  --font-size-xxl: 18px;
  --font-size-xxxl: 20px;
  --font-family-montserrat:"Montserrat"
}

/* Classes */
.montserrat-medium-dawn-pink-12px {
color: var(--dawn-pink);
font-family: var(--font-family-montserrat);
font-size: var(--font-size-m);
font-weight: 500;
font-style: normal;
}
.montserrat-normal-dawn-pink-12px {
color: var(--dawn-pink) ;
font-family: var(--font-family-montserrat);
font-size: var(--font-size-m);
font-weight: 400;
font-style: normal;
}

.montserrat-medium-dawn-pink-16px {
color: var(--dawn-pink) ;
font-family: var(--font-family-montserrat);
font-size: var(--font-size-xl);
font-weight: 500;
font-style: normal;
}


.montserrat-medium-dawn-pink-18px {
color: var(--dawn-pink) ;
font-family: var(--font-family-montserrat);
font-size: var(--font-size=xxl);
font-weight: 500;
font-style: normal;
}

.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.h1text-lg {
  @extend .valign-text-middle;
  @extend .montserrat-medium-dawn-pink-18px;
  align-items: flex-start;
  display: flex;
  padding: 0px 3.17px 0.23px 0px;
  position: relative;
  width: fit-content;
}

@media (max-width: 768px) {
  :root {
    --font-size-s: 10px;
    --font-size-m: 10.5px;
    --font-size-1: 12px;
    --font-size-xl: 14px;
    --font-size-xxl: 16px;
    --font-size-xxxl: 18px;
  }
}
