@import url("https://fonts.googleapis.com/css?family=Montserrat: 400, 500");
@import "vars.scss";


.valign-text-middle {
display: flex;
flex-direction: column;
justify-content
: center;
}


.button-group {
  display: flex;
  margin-top: 8px;
}

.button {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 8px;
  padding: 2px 4px;
  transition: background-color 0.3s ease;
}

button:active {
  background-color:#cccccc;
  transform : translate(0, 2px);
}

.button:hover,
.button:hover {
  background-color: rgba(241, 196, 15, 0.7);
  color: #000;
}

.outer-left {
   border-radius : 6px 0 0 6px;
}

.outer-right {
  border-radius : 0 6px 6px 0; 
}
.volume-slider {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px 4px;
}

.stem {
  align-items: center;
  flex-direction: row;
  display: flex;
  left: 15px;
  min-width: 274px;
  position: relative;
}

.stem-label {
  background: linear-gradient(180deg, #5f7585 14.22%, #1a1a1a 100%);
  border-radius: 6px;
  box-shadow: inset 2px 2px 0px #ffffff0a, inset -2px -2px 0px #0000001a, 0px 4px 34px #00000059;
  height: 116px;
  position: relative;
  width: 290px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: space-between;
}
.stem-label-row {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: center;

}

.subheading-row {
  display: flex;
  align-items: center;
}

.big-button {
  @extend .button; // Inherit properties from the .button class
  font-size: 12px; // Increase the font-size
  padding: 6px 12px; // Increase the padding
  // border-radius: 8px; // Increase the border-radius
}

// .stem-control {
//   border: none;
//   background-color: transparent;
//   color: #ffffff;
//   cursor: pointer;
//   font-size: 8px;
//   padding: 2px 4px;
//   transition: color 0.3s ease;
// }

.subheading {
  font-size: 12px;
  margin: 0 8px;
}

.stem-controls-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}


.stem-controls {
  display: flex;
  flex-direction: column;
  align-items:center;
}


.stem-controls input[type="range"] {
  margin: 10px auto 0; /* Add margin above the slider */
  width: 80%; /* Adjust the slider width as needed */
}

.active {
  background-color: #f1c40f;
  color: #fff;
}

//icons

.stem-icon-container {
  margin-right: 16px;
}

.stem-icon {
    height: 31.99px;
    min-width: 31.99px;
    display: flex;
    align-items: center;
    position: relative;
}

.vector {
    height: 13px;
    margin-top: 1.19px;
    width: 13px;
    }


.waveform{
    // background-image: url("../../public/assets/images/image-1.png") ;
    background-position: 50% 50%;
    background-size: cover;
    height: 100px;
    margin-right: -0.15px;
    min-width: 570.99px;
    position: relative;
}

audio::-webkit-media-controls-play-button {
  background-image: url("../../public/assets/images/play.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
}


.change-track {
    height: 39px;
    // margin-left: 69px;
    width: 75px
}

@media only screen and (max-width: 600px) {
  .stem {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
    left: 0;
    min-width: 100%;
  }
  .stem-label-row {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .stem-name {
    margin-left: 0;
    margin-top: 8px;
  }
  .stem-label {
    height: 50%;
    min-width: 100%;
  }
 
  .waveform {
    min-width: 100%;
  }

  audio::-webkit-media-controls-play-button {
    width: 30px;
    height: 30px;
  }
  .change-track {
    margin-left: 0;
    margin-top: 12px;
    width: 100%;
  }
}
