@import "vars.scss";

.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.bpm {
  color: var(--dawn-pink-2);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20.1px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bpm-input {
    align-items: flex-start;
    background-color: #1d1e22;
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--dawn-pink-2);
    display: flex;
    height: 40px;
    width: 106px;
    position: relative;
    top: 1px;
    margin-bottom:10px;
    text-align: center;
}

.bpm-input-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}



.div-mui-grid-root {
    align-items: flex-start;
    display: flex;
    gap: 26.09px;
    height: 40px;
    position: relative;
    width: fit-content;
}

.span-mui-slider-thumb-color-primary {
    background-color: var(--siren);
    border-radius: 10px;
    height: 20px;
    left: -10px;
    position: absolute;
    top: 85px;
    width: 20px;
    }

.speed-section{
    align-items: center;
    display: flex;
    flex-direction: column;
    // gap: 87.91px;
    padding: 0px 0px 7.09px;
    position: relative;
    width: fit-content;
}

.speed-grid-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.bpm-input-arrows {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bpm-input-arrow {
  width: 10px;
  height: 10px;
//   background-color: #ccc;
  cursor: pointer;
  display: block;
}

.bpm-input-arrow.up {
  transform: rotate(-45deg);
}

.bpm-input-arrow.down {
  transform: rotate(135deg);
}

.bpm-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bpm-input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.tickmarks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tick-options {
  margin-right: 45px;
  margin-left: 45px;  
}


@media only screen and (max-width: 768px) {
    .speed-section {
        gap: 16px;
        align-items: center;
    }
}

@media only screen and (max-width: 576px) {

    .speed-section{
        gap: 8px;
        align-items: center;
    }

    .bpm-input {
        width: 80px;
        // left: 60px;
    }
}
