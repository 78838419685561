@import "css/vars.scss";

body {
 margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hitext-lg {
  align-items: flex-start;
  display: flex;
  padding: 0px 1.96px 0.23px 0px;
  position: relative;
  width: fit-content;
}


/* .main{
  background-color: #101010;
  border: 3px solid;
  border-color: var(--black);
  border-radius: 12px;
  box-shadow: inset 5px 5px 14px 10px E
  #0000003b, 0.8px 0.8px 0px #ffffff1a;
  flex: 1;
  height: 100px;
  min-width: 816.03px;
  overflow: hidden;
  position: relative;
} */

.div-stemplayer2 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(60, 60,60) 14.22%, rgb(26, 26, 26) 190%);
  background-color:rgb(255, 255, 255, 1);
  border: 1px none;
  display: flex;
  gap: 10px;
  min-height: 1600.68px;
  min-width: 1068.02px;
  padding: 19px 0px;
  position: relative;
  width: 100%;
  justify-content: center;
}


.page {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 50px 62px;
  position: relative;
  width: fit-content;
}

.main , .divw-full{
  align-items: center;
  border: 1px solid;
  border-color: var(--black);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  width: fit-content;
}


.divflex { 
  align-items: center;
  display: flex; 
  /* gap: 31.99px; */
  position: relative;
  width: 944px;
  justify-content: space-between;
}


.divflex-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* gap: 5.99px; */
  position: relative;
  width: fit-content;
  justify-content: space-between;
}

.divflex-1 {
  background-color: #101010;
  border: 3px solid;
  border-color: var(--black);
  border-radius: 12px;
  box-shadow: inset 5px 5px 14px 10px #0000003b, 0.8px 0.8px 0px #ffffff1a;
  flex: 1;
  height: 100px;
  /* min-width: 816.03px; */
  overflow: hidden;
  position: relative;
}

.divw-full-1 {
  align-items: center;
  display: flex;
  gap: 15.99px;
  left: 0;
  padding: 0px 0px 0px 8px;
  position: absolute;
  /* top: 197px; */
  width: 753px;
}

.stemrow {
    align-items: center;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    gap: 31.99px;
    padding: 0px 24px 0px 0px;
    position: relative;
    width: fit-content;
    flex-wrap: wrap;
    
}


.frame-1 {
  align-items: flex-end;
  display: flex;
  gap: 244px;
  position: relative;
  width: fit-content;
  bottom: 15px;
}

.frame-1-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 40px;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
}


.frame-1-1-1{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

/* .frame-1-1-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

/* .frame-1-1-1 > * {
  flex: 1 1 45%;
  margin-bottom: 20px;
}
 */

.h1text-lg-1 {
  align-items: flex-start;
  display: flex;
  padding: 0px 3.17px 0.23px 0px;
  position: relative;
  width: fit-content;
}
/* 
@media screen and (max-width: 1200px) {
 .div-stemplayer2, .divflex, .divbg-black .divflex-3 {
   width: 100%;
  }
  
} */
@media screen and (max-width: 1100px) {
  .div-stemplayer2 {
    min-height: 1000px;
    width: 100%;
    padding: 0px 0;
    /* min-width: 500px; */
    
  }

  .main, .divw-full {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .div-stemplayer2 {
    min-height: 1000px;
    padding: 10px 0;
    justify-content: flex-start;
  }

 .main, .divw-full {
    width: 100%;
  }
  .frame-1 {
    flex-direction: column;
  }

  .frame-1-1{
      flex-direction: column;
    }
    
  /* .frame-1-1-1 > * {
    flex: 1 1 100%;
  } */

  
  .page {
    padding: 50px 20px;
  }
  
  .divflex, .divbg-black  {
    flex-direction: row;
    gap: 0px;
    width: 100%
  }
  
  .divflex-3 {
    gap: 15px;
  }
  
  .divw-full-1 {
    flex-wrap: wrap;
    width: 100%;
    position: static;
  }
  
  
  .hitext-lg {
    width: 100%;
  }
}
/* Define a breakpoint for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  /* Apply different styles for screens smaller than 768px */
  /* For example, change the layout to a single column */
  .main {
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }
  .divflex, .divbg-black .divflex-3 {
   width: 100%;
  }
  
}

