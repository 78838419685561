.waveform{
    // background-image: url("../../public/assets/images/image-1.png") ;
    background-position: 50% 50%;
    background-size: cover;
    height: 100px;
    min-width: 696.99px;
    position: relative;
    border: 1px solid;
    border-color: var(--black);
}

.divbg-black {
  align-items: flex-start;
  background-color: var(--black);
  border: 5px solid;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  padding: 5.88px 0px 5.88px 5.88px;
  position: relative;
  width: 944px;
}

.divflex-2 {
  height: 100px;
  margin-right: -0.8px;
  min-width: 96.8px;
  position: relative;
  background: linear-gradient(180deg, #5f7585 14.22%, #1a1a1a 100%);
  border-radius: 30px;
  border: 4px solid var(--black);
  box-shadow: inset 2px 2px 0px #ffffff0a, inset -2px -2px 0px #0000001a, 0px 4px 34px  #00000059;
}

.icon {
  height:  20px;
  min-width: 20px;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 900px) {
.divbg-black  {
    flex-direction: row;
    gap: 0px;
    width: 100%;
  }
}


@media (max-width: 768px) {
  .waveform {
    min-width: 100%;
    max-width: 100%;
  }
   .divbg-black {
   width: 100%;
  }
  
}